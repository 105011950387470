import "./cta.scss";

import { ReactNode } from "react";
import cn from "clsx";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  CtaList,
  CtaListProps,
} from "design-system/components/primitives/cta-list/cta-list";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  Alignment,
  EditAttributes,
  MediaAlignment,
} from "design-system/types/types";
import { Theme } from "design-system/utils/theme";

export interface CtaPropsEditAttributes {
  block?: EditAttributes;
  title?: EditAttributes;
  subtitle?: EditAttributes;
}

export interface CtaProps {
  theme?: Theme;
  align?: Alignment;

  title?: ReactNode;
  subtitle?: ReactNode;

  media?: Array<MediaAssetProps>;
  mediaAlignment?: MediaAlignment;

  ctaList?: CtaListProps;
  primaryCta?: CtaLinkProps;
  secondaryCta?: CtaLinkProps;
  HeadingLevel?: "h2" | "h3";

  editAttributes?: CtaPropsEditAttributes;
}

/**
 *
 * ## See it in use on...
 * - The [kitchen sink article page](/story/example-pages-article-pages--kitchen-sink-article)
 *
 *
 * ### with Buttons, 2 assets, and theme
 * - **`id: CB-002-002-01`**
 * - **`data-region: cb__cta__large__button`**
 */
export function Cta({
  align,
  ctaList,
  primaryCta,
  secondaryCta,
  subtitle,
  theme,
  title,
  media,
  mediaAlignment = "right",
  editAttributes,
  HeadingLevel = "h2",
}: CtaProps) {
  const hasButton = (!ctaList && primaryCta) || secondaryCta;
  const hasArt = media && media.length > 0;
  const alignWidthDefaults = align ? align : hasArt ? "full" : "center";
  const wrapperClass = cn(
    `hbs-global-align-${alignWidthDefaults}`,
    "hbs-component--cta",
  );

  return (
    <div
      {...editAttributes?.block}
      className={wrapperClass}
      data-region={`cb__cta__large__${ctaList ? "linklist" : "button"}`}
    >
      <div
        className={cn(
          "hbs-cta",
          media
            ? `hbs-cta--asset hbs-cta--asset-count-${media.length}`
            : `hbs-cta--text-only`,
          `hbs-cta--${theme ? "themed" : "flat"}`,
        )}
        data-theme={theme}
      >
        <div
          className={cn(
            "hbs-cta__wrapper",
            mediaAlignment === "left"
              ? "hbs-cta__wrapper--media-left"
              : "hbs-cta__wrapper--media-right",
          )}
        >
          {/* Content */}
          <div className="hbs-cta__content">
            {title && (
              <HeadingLevel
                {...editAttributes?.title}
                className="hbs-cta__title"
              >
                {title}
              </HeadingLevel>
            )}

            {subtitle && (
              <div {...editAttributes?.subtitle} className="hbs-cta__subtitle">
                {subtitle}
              </div>
            )}

            {hasButton && (
              <div className="hbs-cta__cta-wrapper">
                {primaryCta && (
                  <CtaLink {...primaryCta}>{primaryCta.children}</CtaLink>
                )}
                {secondaryCta && (
                  <CtaLink {...secondaryCta}>{secondaryCta.children}</CtaLink>
                )}
              </div>
            )}

            {ctaList && (
              <div className="hbs-cta__cta-wrapper">
                <CtaList {...ctaList} />
              </div>
            )}
          </div>

          {/* Media */}
          {media && (
            <div className="hbs-cta__media">
              {media.map((asset, index) => (
                <MediaAsset
                  key={index}
                  {...asset}
                  aspectRatio={
                    media.length === 1 ? "3/2" : index === 1 ? "3/2" : "3/4"
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
